import axios from 'axios'

// Initial state set here
const getDefaultState = () => {
    return {
        activeProjects: [],
        pastProjects: [],
        deletedProjects: [],
        temp: false,
        documentCreated: false,
        documentAssigned: false,
        project: {collaborators:[]},
        documents: [],
        collaborators: [],
    }
  }

const PublicProjectModule = {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        activeProjects(state) {
            return state.activeProjects
        },
        pastProjects(state) {
            return state.pastProjects
        },
        deletedProjects(state) {
            return state.deletedProjects
        },
        project(state) {
            return state.project
        },
        documents(state) {            
            // return state.documents.map((item)=> {
            //     item.collaborators_ids = [];
            //     return item;
            // });
            return state.documents;
        },
        collaborators(state) {
            return state.collaborators;
        }
    },

    mutations: {
        SET_ACTIVE_PROJECTS(state, data) {
            state.activeProjects = data
        },
        SET_PAST_PROJECTS(state, data) {
            state.pastProjects = data
        },
        SET_DELETED_PROJECTS(state, data) {
            state.deletedProjects = data
        },
        SET_BOOL(state, data) {
            state.temp = data;
        }, 
        SET_PROJECT(state, data) {
            state.project = data
        }, 
        SET_DOCUMENTS(state, data) {
            state.documents = data
        }, 
        SET_COLLABORATORS(state, data) {
            state.collaborators = data
        }, 
        DOCUMENTS_CREATED(state, data) {
            state.documentCreated = data
        }, 
        DOCUMENTS_ASSIGNED(state, data) {
            state.documentAssigned = data
        }, 
        UPDATE_PROJECT_COLLABORATOR(state, {data, collaboratorId}) {                       
            state.project.collaborators.map((item)=> {
                if(item.id === collaboratorId) {
                    item.documents = data;
                    item.expanded = true;
                } else {
                    item.expanded = false;    
                    item.documents = [];
                }
                return item;
            })            
        },
        COLLAPSE_COLLABORATOR_DOCUMENT(state, collaboratorId) {                       
            state.project.collaborators.map((item)=> {
                if(item.id === collaboratorId) {                    
                    item.expanded = false;
                    item.documents = [];
                } 
                return item;
            })            
        },
        REMOVE_COLLABORATOR(state, collaboratorId) {
            const i = state.collaborators.map(item => item.id).indexOf(collaboratorId);
            state.collaborators.splice(i, 1);    
        },  
        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        },      
    },

    actions: {
        storePrimaryInfo({ commit }, data, config) {
            return axios
                .post('/public/projects/create', data, config)
                .then((res) => {                                              
                    commit('SET_PROJECT', res.data.data);     
                    return res;                  
                })
        },
        updatePrimaryInfo({ commit }, {formData, projectId}, config) {
            return axios
                .post(`/public/projects/${projectId}/update`, formData, config)
                .then((res) => {                                              
                    commit('SET_PROJECT', res.data.data);     
                    return res;                  
                })
        },
        getPrimaryInfo({ commit }, projectId) {
            return axios
                .get(`/public/projects/${projectId}/primary-info`)
                .then((res) => {                                              
                    commit('SET_PROJECT', res.data.data);     
                    return res;                  
                })
        },
        storeDeadlines({ commit }, data) {
            return axios
                .put(`/public/projects/${data.id}/set-deadline`, data.payload)
                .then((res) => {                                                  
                    commit('SET_PROJECT', res.data.data);    
                    return res;                   
                })
        },
        storeTotalCollaborators({ commit }, {total_collaborators, projectId}) {           
            return axios
                .post(`/public/projects/${projectId}/total-collaborators`, {total_collaborators:total_collaborators})
                .then((res) => {                                                  
                    commit('SET_PROJECT', res.data.data);    
                    return res;                   
                })
        },
        getDocuments({ commit }, projectId) {           
            return axios
                .get(`/public/projects/${projectId}/documents`)
                .then((res) => {                                                  
                    commit('SET_DOCUMENTS', res.data.data);    
                    return res;                   
                })
        },
        storeDocuments({ commit }, {formData, projectId}, config) {           
            return axios
                .post(`/public/projects/${projectId}/documents`, formData, config)
                .then((res) => {                                                  
                    commit('DOCUMENTS_CREATED', true);    
                    return res;                   
                })
        },
        removeDocument(context, {projectId, documentId}) {
            return axios
                .delete(`/public/projects/${projectId}/documents/${documentId}`)
                .then((res) => {                          
                    return res;
                })
        },     
        removeDocumentTemplate(context, {projectId, documentId}) {
            return axios
                .delete(`/public/projects/${projectId}/documents/${documentId}/template`)
                .then((res) => {                          
                    return res;
                })
        }, 
        getSupportStaffs(context, projectId) {           
            return axios
                .get(`/public/projects/${projectId}/support-staffs`)
                .then((res) => {                        
                    return res;                   
                })
        }, 
        storeSupportStaffs({ commit }, {supportStaffs, projectId}) {           
            return axios
                .post(`/public/projects/${projectId}/support-staffs`, {supportStaffs:supportStaffs})
                .then((res) => {                                                  
                    commit('SET_PROJECT', res.data.data);    
                    return res;                   
                })
        },
        removeUser({ commit }, {projectId, userId, userType}) {
            return axios
                .delete(`/public/projects/${projectId}/users/${userId}?user_type=${userType}`)
                .then((res) => {  
                    if(userType === 'COLLABORATOR') {
                        commit(`REMOVE_${userType}`, userId);
                    }           
                    return res;
                })
        },
        startProject(context, projectId) {           
            return axios
                .post(`/public/projects/${projectId}/start-project`, {})
                .then((res) => {                    
                    return res;                   
                })
        },
        deleteAttachment({ commit }, data) {
            return axios
                .delete(`/public/projects/${data.project_id}/attachments/${data.attachment_id}`)
                .then((res) => {                                              
                    commit('SET_BOOL', res.data.data);     
                    return res;
                })
        }, 
        getExternalActiveProjects({ commit }) {
            return axios.
                get('/public/projects?status=ACTIVE')
                .then((res) => {                    
                    commit('SET_ACTIVE_PROJECTS', res.data.data);
                })
        },
        getExternalPastProjects({ commit }) {
            return axios.
                get('/public/projects?status=PAST')
                .then((res) => {                    
                    commit('SET_PAST_PROJECTS', res.data.data);
                })
        },
        getExternalDeletedProjects({ commit }) {
            return axios.
                get('/public/projects?status=DELETED')
                .then((res) => {                    
                    commit('SET_DELETED_PROJECTS', res.data.data);
                })
        },
        getAccountExternalData() {
            return axios.
                get('/account-status/account-external')
                .then((res) => {                    
                    return res;
                })
        },
        
        
    }
}

export default PublicProjectModule
