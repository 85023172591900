import axios from 'axios'

// Initial state set here
const getDefaultState = () => {
    return {
        personalAnnualplans: [],
        teamAnnualplans: [],
        personalMonthlyplans: [],
        teamMonthlyplans: [],
        paymentMethods: [],
        teamPlans: [],
        teamPlansAccount: [],
        personalPlans: null,
        defaultPlans: null,
        personalAccountData: null,
        teamAccountData: [],
    }
}

const PricingPlansModule = {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        personalAnnual(state) {
            return state.personalAnnualplans
        },
        personalMonthly(state) {
            return state.personalMonthlyplans
        },
        teamAnnual(state) {
            return state.teamAnnualplans
        },
        teamMonthly(state) {
            return state.teamMonthlyplans
        },
        paymentMethods(state) {
            return state.paymentMethods
        },
        teamPlans(state) {
            return state.teamPlans
        },
        teamPlansAccount(state) {
            return state.teamPlansAccount
        },
        personalPlans(state) {
            return state.personalPlans
        },
        defaultPlans(state) {
            return state.defaultPlans
        },
        personalAccountData(state){
            return state.personalAccountData
        },
        teamAccountData(state){
            return state.teamAccountData
        },
    },

    mutations: {
        SET_PERSONAL_ANNUAL_PLANS(state, data) {
            state.personalAnnualplans = data
        },
        SET_PERSONAL_MONTHLY_PLANS(state, data) {
            state.personalMonthlyplans = data
        },
        SET_TEAM_ANNUAL_PLANS(state, data) {
            state.teamAnnualplans = data
        },
        SET_TEAM_MONTHLY_PLANS(state, data) {
            state.teamMonthlyplans = data
        },
        SET_PAYMENT_METHODS(state, data) {
            state.paymentMethods = data
        },
        SET_TEAM_PLANS(state, data) {
            state.teamPlans = data
        },
        SET_TEAM_PLANS_ACCOUNT(state, data) {
            state.teamPlansAccount = data
        },
        SET_PERSONAL_PLANS(state, data) {
            state.personalPlans = data
        },
        SET_DEFAULT_PLANS(state, data) {
            state.defaultPlans = data
        },
        SET_PERSONAL_ACCOUNT_DATA(state, data) {
            state.personalAccountData = data
        },
        SET_TEAM_ACCOUNT_DATA(state, data) {
            state.teamAccountData = data
        },
        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        }
    },

    actions: {
        getPersonalAnnualplans({ commit }) {
            return axios.
                get('plans?plan_type=personal&time_period=annually')
                .then((res) => {
                    commit('SET_PERSONAL_ANNUAL_PLANS', res.data);
                    return res;
                })
        },
        getPersonalMonthlyplans({ commit }) {
            return axios.
                get('plans?plan_type=personal&time_period=monthly')
                .then((res) => {                    
                    commit('SET_PERSONAL_MONTHLY_PLANS', res.data);
                    return res;
                })
        },
        getTeamAnnualplans({ commit }) {
            return axios.
                get('plans?plan_type=team&time_period=annually')
                .then((res) => {                    
                    commit('SET_TEAM_ANNUAL_PLANS', res.data);
                    return res;
                })
        },
        getTeamMonthlyplans({ commit }) {
            return axios.
                get('plans?plan_type=team&time_period=monthly')
                .then((res) => {                    
                    commit('SET_TEAM_MONTHLY_PLANS', res.data);
                    return res;
                })
        },
        saveNewCreditCard(context, data) {           
            return axios
                .post(`/payment-method`, data)
                .then((res) => {        
                    return res;                   
                })
        },
        getPaymentMethods({ commit }) {
            return axios.
                get('/payment-methods')
                .then((res) => {  
                    //console.log(res.data);                  
                    commit('SET_PAYMENT_METHODS', res.data.data);
                    return res;
                })
        },
        deleteSavedPlan(context, id) {
            return axios
                .delete(`/payment-methods/${id}`)
                .then((res) => {                          
                    return res;
                })
        }, 
        saveDefaultPlan(context, data) {           
            return axios
                .post(`/default-plans`, data)
                .then((res) => {        
                    return res;                   
                })
        },
        getDefaultPlans({ commit }) {           
            return axios
                .get(`/default-plans`)
                .then((res) => {    
                    commit('SET_DEFAULT_PLANS', res.data.data); 
                    return res;                   
                })
        },
        getTeamPlans({ commit }) {
            return axios.
                get('/account-status/team-plans')
                .then((res) => {  
                    //console.log(res.data);                  
                    commit('SET_TEAM_PLANS', res.data.data);
                    return res;
                })
        },
        getTeamPlansAccount({ commit }) {
            return axios.
                get('/account/team-plans')
                .then((res) => {  
                    //console.log(res.data);                  
                    commit('SET_TEAM_PLANS_ACCOUNT', res.data.data);
                })
        },
        cancelTeamSubscription(context, id) {
            return axios
                .delete(`teams/${id}/subscription-cancel`)
                .then((res) => {                          
                    return res;
                })
        },
        getPersonalPlans({commit}) {
            return axios.
                get('/account-status/personal-plans')
                .then((res) => {  
                    //console.log(res.data);                  
                    commit('SET_PERSONAL_PLANS', res.data.data);
                    return res;
                })
        },
        subscribePersonalPlan(context, data) {           
            return axios
                .post(`/subscribe-personal-plans`, data)
                .then((res) => {        
                    return res;                   
                })
        },
        getPlanForSummary() {           
            return axios
                .get(`/subscribed-plan-info`)
                .then((res) => {        
                    return res;                   
                })
        },
        cancelPersonalSubscription() {
            return axios
                .delete(`personal/subscription-cancel`)
                .then((res) => {                          
                    return res;
                })
        },
        getPersonalAccountData({ commit }) {
            return axios
            .get('/account/personal')
            .then((res) => {                                              
                commit('SET_PERSONAL_ACCOUNT_DATA', res.data);     
                return res;            
            })
        },
        getTeamAccountData({commit}) {
            return axios
            .get('/account/team')
            .then((res) => {                                              
                commit('SET_TEAM_ACCOUNT_DATA', res.data.data);                  
            })
        },
    }
}

export default PricingPlansModule
