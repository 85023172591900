import axios from 'axios'

// Initial state set here
const getDefaultState = () => {
    return {
        authToken: '',
        expires_in: '',
        permisions: '',
        user: {},
        sign: false,
    }
}

const AuthModule = {

    namespaced: true,

    state: getDefaultState(),

    getters: {
        isLoggedIn(state) {
            return (state.expires_in > Date.now()) && state.authToken ? true : false
        },
        getToken(state) {
            return state.authToken;
        },
        tokenExpiresIn(state) {
            return state.expires_in;
        },
        authUser(state) {
            return state.user;
        },
        token(state) {
            return `Bearer ${state.authToken}`;
        }
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.authToken = token
        },
        SET_BOOL(state, val) {
            state.sign = val
        },
        SET_ESPIRES_IN(state, expires_in) {
            state.expires_in = Date.now() + (expires_in * 1000)  //converting to unix timestamp             
        },
        CLEAR_AUTH_TOKEN(state) {
            state.authToken = '';
        },
        SET_PERMISSIONS(state, permissions) {
            state.permisions = permissions;
        },
        SET_AUTH_USER(state, user) {
            state.user = user;
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        }
    },

    actions: {
        signup({ commit }, credentials) {
            return axios
                .post('/auth/signup', credentials)
                .then(() => {
                    commit('SET_BOOL', true);
                })
        },
        passcode({ commit }, credentials) {
            return axios
                .post('/auth/send-passcode', credentials)
                .then((res) => {
                    commit('SET_BOOL', true);

                })
        },
        resendPassword({ commit }, credentials) {
            return axios
                .post('/auth/resend-password', credentials)
                .then((res) => {
                    commit('SET_BOOL', true);
                })
        },
        validatePassword({ commit }, credentials) {
            return axios
                .post('/auth/validate-password', credentials)
                .then((res) => {
                    commit('SET_BOOL', true);
                })
        },
        changePassword({ commit }, credentials) {
            return axios
                .post('/auth/change-password', credentials)
                .then((res) => {
                    commit('SET_BOOL', true);
                })
        },
        signin({ commit, dispatch }, credentials) {
            return axios
                .post('/auth/signin', credentials)
                .then((res) => {
                    commit('SET_TOKEN', res.data.access_token);
                    commit('SET_ESPIRES_IN', res.data.expires_in);
                    dispatch('getUserProfile');
                })
        },
        forgotPassword({ commit }, credentials) {
            return axios
                .post('/auth/forgot-password', credentials)
                .then((res) => {
                    commit('SET_BOOL', true);
                })
        },
        refresh({ commit }) {
            return axios
                .get('/auth/refresh')
                .then((res) => {
                    commit('SET_TOKEN', res.data.access_token);
                    commit('SET_ESPIRES_IN', res.data.expires_in);
                })
        },
        logout({ commit, dispatch }) {
            return axios
                .get('/auth/logout')
                .then(() => {
                    commit('CLEAR_AUTH_TOKEN')
                    commit('RESET_STATE')
                    dispatch('clearToken')
                })
        },
        clearToken({ commit }) {
            commit('CLEAR_AUTH_TOKEN');
            commit('notifications/RESET_STATE', null, { root: true });
            commit('pricingPlans/RESET_STATE', null, { root: true });
            commit('privateProjects/RESET_STATE', null, { root: true });
            commit('profiles/RESET_STATE', null, { root: true });
            commit('projectLists/RESET_STATE', null, { root: true });
            commit('publicProjects/RESET_STATE', null, { root: true });
            commit('snackbar/RESET_STATE', null, { root: true });
            commit('stripes/RESET_STATE', null, { root: true });
            commit('teamPrivateProjects/RESET_STATE', null, { root: true });
            commit('teamProjectLists/RESET_STATE', null, { root: true });
            commit('teamPublicProjects/RESET_STATE', null, { root: true });
            commit('teams/RESET_STATE', null, { root: true });
            commit('RESET_STATE')
        },
        getUserProfile({ commit }) {
            return axios
                .get('/auth/me')
                .then((res) => {

                    commit('SET_AUTH_USER', res.data);
                })
        },
    }
}

export default AuthModule