<template>
    <div class="auth-section">
        <div class="image-section">
            <div class="image-content">
                <!--<img class="mx-auto img-fluid left-logo mt-5" width="230" height="65"
                    src="../../assets/images/logo.png"
                    alt="Logo">-->
                <h1 class="mt-3">Welcome to LongLeap.One</h1>
                <div class="mt-3">
                    <img class="img-fluid" src="../../assets/images/login.png" alt="">
                </div>
            </div>
        </div>
        <div class="form-section">
            <div class="form-content">
                <img id="longleap_logo" class="mx-auto img-fluid" width="183" height="53"
                    src="../../assets/images/logo.png"
                    alt="Logo"/>

                <h1 class="pageTitle m-0">Password Reset</h1>

                <el-form
                    ref="ruleForm"
                    :model="formData"
                    :rules="rules"
                    class="mt-3 demo-ruleForm"
                    label-position="top"
                >

                    <el-form-item label="New Password" prop="password">
                        <el-input
                            v-model="formData.password"
                            placeholder="********"
                            autocomplete="off"
                            @keyup.enter.native="handleSubmit('ruleForm')"
                            :type="showPassword ? 'text' : 'password'"
                        >
                            <el-button slot="append" @click.prevent="showPassword = !showPassword">
                                <svg v-if="showPassword" style="width: 15px; height: 15px" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                </svg>
                                <svg v-if="!showPassword" style="width: 15px; height: 15px" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                                </svg>
                            </el-button>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Confirm Password" prop="password_confirm">
                        <el-input
                            v-model="formData.password_confirm"
                            placeholder="********"
                            autocomplete="off"
                            @keyup.enter.native="handleSubmit('ruleForm')"
                            :type="showConfirmPassword ? 'text' : 'password'"
                        >
                            <el-button slot="append" @click.prevent="showConfirmPassword = !showConfirmPassword">
                                <svg v-if="showConfirmPassword" style="width: 15px; height: 15px" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                </svg>
                                <svg v-if="!showConfirmPassword" style="width: 15px; height: 15px" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                                </svg>
                            </el-button>
                        </el-input>
                    </el-form-item>

                    <el-button
                        class="mt-3"
                        style="width: 100%"
                        type="success"
                        @click.prevent="handleSubmit('ruleForm')">Submit
                    </el-button>

                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";

export default {
    name: "PasswordReset",
    props: ["id"],
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please input the password'));
            } else {
                if (this.formData.password_confirm !== '') {
                    this.$refs.ruleForm.validateField('password_confirm');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please input the password again'));
            } else if (value !== this.formData.password) {
                callback(new Error('Two inputs don\'t match!'));
            } else {
                callback();
            }
        };

        return {
            formData: {
                password: '',
                password_confirm: '',
            },

            rules: {
                password: [
                    {
                        validator: validatePass,
                        trigger: 'blur'
                    },
                    {
                        trigger: 'blur',
                        validator (rule, value, callback) {
                          if (/^(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()]{8,}$/.test(value)) {
                            callback();
                          } else {
                            callback(new Error('Minimum 8 characters, One capital latter, Special charater, Alphanumeric'));
                          }
                        }
                    }
                ],
                password_confirm: [
                    {
                        validator: validatePass2,
                        trigger: 'blur'
                    },
                    {
                        min: 8,
                        message: 'Password must be at least 8 characters',
                        trigger: 'blur'
                    }

                ],

            },

            showPassword: false,
            showConfirmPassword: false,
            loading: false,

            passcode: null,
            email: null
        }
    },
    mounted() {
        this.checkToken();
    },
    methods: {
        checkToken() {
            axios.get(`/user-reset-password/${this.id}`)
                .then(response => {
                    this.passcode = response.data.passcode;
                    this.email = response.data.email;
                })
                .catch(() => {
                    Notification.error('Invalid token');
                    window.location.href = "/signup";
                })
        },

        handleSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.passwordReset();
                } else {
                    return false;
                }
            });
        },

        passwordReset() {
            axios.post('/auth/change-password', {
                password: this.formData.password,
                passcode: this.passcode,
                email: this.email,
            })
                .then(() => {
                    this.callToBackendLoginApi();
                })
                .catch(error => {
                    Notification.error(error.response.data.message);
                })
        },

        callToBackendLoginApi() {
            this.loading = true;
            this.$store.dispatch("auth/signin", {
                email: this.email,
                password: this.formData.password,
                passcode: this.passcode,
                remember_me: false
            })
                .then(() => {
                    this.$router.push({path: "/main-dashboard"});
                })
                .catch(() => {
                    Notification.error('Invalid verification code!');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.auth-section {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    flex-wrap: wrap;
}

.image-section {
    width: 50%;
    background: #F3F3F3;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
}

.image-section h1, .form-section h1 {
    font-weight: 700;
    margin-top: 30px;
}

.form-section {
    width: 50%;
    background: #FFFFFF;
    padding: 50px 20px;
}

.form-section img {
    display: none;
}

@media (max-width: 991px) {
    .image-section {
        display: none;
    }
    .form-section h1 {
        font-size: 30px;
        text-align: center;
    }
    .form-content img {
        width: 200px;
        margin-bottom: 30px;
    }

    .form-section {
        width: 100%;
    }

    .form-section img {
        text-align: center;
        display: block;
    }
}
@media (max-width: 767px) {
    .form-section h1 {
        font-size: 24px;
        text-align: center;
    }
    
    .form-section {
        padding: 30px 20px;
    }
    .form-content img {
    width: 150px;
    margin-bottom: 15px;
}
}
@media (max-width: 576px) {
    .pageTitle {
        font-size: 23px !important;
    }
}
</style>