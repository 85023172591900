import axios from 'axios'

const SubscriptionModule = {
    namespaced: true,

    state: {
        
    },

    getters: {
        currentSubscription() {
            return "HI";
        }
    },

    mutations: {

    },

    actions: {

    }
}

export default SubscriptionModule
