import axios from 'axios'

// Initial state set here
const getDefaultState = () => {
    return {
        activeProjects: [],
        draftProjects: [],
        pastProjects: [],
        deletedProjects: [],
    }
}

const TeamProjectListModule = {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        activeProjects(state) {
            return state.activeProjects
        },
        draftProjects(state) {
            return state.draftProjects
        },
        pastProjects(state) {
            return state.pastProjects
        },
        deletedProjects(state) {
            return state.deletedProjects
        }
    },

    mutations: {
        SET_ACTIVE_PROJECTS(state, data) {
            state.activeProjects = data
        },
        SET_DRAFT_PROJECTS(state, data) {
            state.draftProjects = data
        },
        SET_PAST_PROJECTS(state, data) {
            state.pastProjects = data
        },
        SET_DELETED_PROJECTS(state, data) {
            state.deletedProjects = data
        },
        RESTORE_DELETED_PROJECT(state, project) {
            const i = state.deletedProjects.map(item => item.id).indexOf(project.id);
            state.deletedProjects.splice(i, 1); 
        },
        ADD_DRAFT_PROJECT(state, data) {
            state.draftProjects.unshift(data)
        },
        DELETE_PROJECT(state, project) {
            let statusObj = {"ACTIVE": "activeProjects", "DRAFT": "draftProjects", "PAST": "pastProjects", "DELETED": "deletedProjects"}
            const i = state[statusObj[project.status]].map(item => item.id).indexOf(project.id);
            state[statusObj[project.status]].splice(i, 1); 
        },
        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        }
    },

    actions: {
        getActiveProjects({ commit }, id) {
            return axios.
                get(`/team/projects/${id}?status=ACTIVE`)
                .then((res) => {                    
                    commit('SET_ACTIVE_PROJECTS', res.data.data);
                })
        },
        getDraftProjects({ commit }, id) {
            return axios.
                get(`/team/projects/${id}?status=DRAFT`)
                .then((res) => {                    
                    commit('SET_DRAFT_PROJECTS', res.data.data);
                })
        },
        getPastProjects({ commit }, id) {
            return axios.
                get(`/team/projects/${id}?status=PAST`)
                .then((res) => {                    
                    commit('SET_PAST_PROJECTS', res.data.data);
                })
        },
        getDeletedProjects({ commit }, id) {
            return axios.
                get(`/team/projects/${id}?status=DELETED`)
                .then((res) => {                    
                    commit('SET_DELETED_PROJECTS', res.data.data);
                })
        },
        copyProject({ commit }, projectId) {           
            return axios
                .post(`/team/projects/${projectId}/copy-project`,{})
                .then((res) => {                                                  
                    commit('ADD_DRAFT_PROJECT', res.data.data);    
                    return res;                   
                })
        },
        restoreProject({ commit }, id) {
            return axios
                .post(`/team/projects/${id}/restore`)
                .then((res) => {                                              
                    commit('RESTORE_DELETED_PROJECT', res.data.data);     
                    return res;
                })
        }, 
        deleteProject({commit}, project) {
            return axios
                .delete(`/team/projects/${project.id}`)
                .then((res) => {                                              
                    commit('DELETE_PROJECT', project);     
                    return res;
                })
        },   
        sendEmailDecisions(context, {projectId, collaboratorId}) {
            return axios
                .post(`/team/projects/${projectId}/collaborators/${collaboratorId}/send-decisions`)
                .then((res) => {                          
                    return res;
                })
        }, 
        forceDeleteProject(context, id) {
            return axios
                .delete(`/team/projects/${id}/force-delete`)
                .then((res) => {                                              
                    return res;
                })
        },
    }
}

export default TeamProjectListModule
