import axios from 'axios'

// Initial state set here
const getDefaultState = () => {
    return {
        temp: false
    }
  }

const StripeModule = {
    namespaced: true,

    state: getDefaultState(),

    mutations: {
        SET_BOOL(state, data) {
            state.temp = data;
        },
        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        }
    },

    actions: {
        checkout({ commit }, payment_method) {
            return axios
                .post('/subscription', payment_method)
                .then((res) => {      
                    commit('SET_BOOL', true);
                })
        }
    }
}

export default StripeModule
